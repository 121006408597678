import { type Route } from './routes';
import { isObject, isString, hasProperty } from './typeGuards';

interface ConversionEvent {
  type: EventType;
  page: Route;
  data?: Record<string, unknown>;
}

const VALID_EVENT_TYPES = [
  'page_view',
  'button_click', 
  'funnel_step_transition',
  'form_submit',
  'purchase',
  'cta_click',
  'signup_start',
  'deploy',
  'login_attempt',
  'login_success',
  'signup_google',
  'login_google',
  'logout',
  'path_selected',
  'plan_selected',
  'checkout_start',
  'tool_access',
  'assessment_selection',
  'assessment_complete'
] as const;

type EventType = typeof VALID_EVENT_TYPES[number];

function isValidEventType(type: unknown): type is EventType {
  return typeof type === 'string' && VALID_EVENT_TYPES.includes(type as EventType);
}

interface FunnelState {
  source: string;
  startTime: number;
  step: number;
  completed: boolean;
  completedAt?: number;
}

// Initialize analytics storage
const initAnalytics = () => {
  if (typeof window === 'undefined') return;
  
  try {
    const conversions = localStorage.getItem('conversions');
    const sessionId = localStorage.getItem('sessionId');
    
    // Validate or initialize conversions
    localStorage.setItem('conversions', 
      conversions && isValidJSON(conversions) ? conversions : '[]'
    );
    
    // Validate or initialize session ID
    localStorage.setItem('sessionId',
      sessionId || Math.random().toString(36).substring(2)
    );
  } catch (error) {
    console.error('Failed to initialize analytics:', error);
  }
};

export function trackConversion(event: ConversionEvent) {
  try {
    // Skip invalid events, extension-related events, and null/undefined
    if (!event) {
      return;
    }

    // Skip extension-related events and errors
    if (event instanceof Error && (
      event.stack?.includes('chrome-extension') ||
      event.stack?.includes('inpage.js') ||
      event.stack?.includes('metamask') ||
      event.stack?.includes('web3') ||
      event.stack?.includes('wallet') ||
      event.stack?.includes('startswith')
    )) {
      console.debug('Skipping extension-related event');
      return;
    }

    // Validate event structure
    if (!isValidEvent(event)) {
      console.debug('Invalid event format:', event);
      return;
    }

    // Initialize analytics first
    initAnalytics();

    // Safely extract and validate event properties
    const eventType = event.type;
    const eventPage = event.page;

    // Validate event type with type guard
    if (!eventType || !isValidEventType(event.type)) {
      return;
    }

    // Validate page path
    if (!eventPage || !isString(event.page)) {
      return;
    }
    
    // Get funnel state with error handling
    let funnelState = getFunnelState();
    
    // Ensure event data is safe to store
    const safeEventData = {
      type: event.type,
      page: event.page,
      data: event.data && typeof event.data === 'object' ?
        // Filter out any null/undefined values
        Object.fromEntries(
          Object.entries(event.data).filter(([_, v]) => v !== null && v !== undefined)
        ) : {},
      timestamp: new Date().toISOString(),
      sessionId: getSessionId(),
      funnelState
    };

    // Store conversion with error handling
    storeConversion(safeEventData);

  } catch (error) {
    console.warn('Analytics error:', error);
    // Don't throw error to prevent app crashes
    return;
  }
}

function isValidEvent(event: unknown): event is ConversionEvent {
  if (!event) {
    return false;
  }

  // Must be an object
  if (typeof event !== 'object' || event === null) {
    return false;
  }

  // Safely check type property
  if (!('type' in event) || !('page' in event)) {
    return false;
  }

  const { type, page } = event as any;

  // Type must be string
  if (typeof type !== 'string') {
    return false;
  }

  // Skip extension-related events
  const skipPatterns = [
    'extension',
    'chrome-extension',
    'inpage.js',
    'web3',
    'metamask',
    'wallet'
  ];
  
  if (skipPatterns.some(pattern => type.includes(pattern))) {
    console.debug('Skipping extension-related event type');
    return false;
  }

  // Page must be string
  if (typeof page !== 'string') {
    return false;
  }

  // Skip extension-related pages
  if (skipPatterns.some(pattern => page.includes(pattern))) {
    console.debug('Skipping extension-related page');
    return false;
  }

  return true;
}

// Helper functions
function getFunnelState() {
  try {
    const stored = localStorage.getItem('funnelState');
    if (!stored) {
      return null;
    }
    const state = JSON.parse(stored);
    return isObject(state) ? state : null;
  } catch (error) {
    console.error('Error getting funnel state:', error);
    return null;
  }
}

function getSessionId(): string {
  let sessionId = localStorage.getItem('sessionId');
  if (!sessionId) {
    sessionId = Math.random().toString(36).substring(2);
    localStorage.setItem('sessionId', sessionId);
  }
  return sessionId;
}

function storeConversion(eventData: any) {
  try {
    const stored = localStorage.getItem('conversions') || '[]';
    const conversions = JSON.parse(stored);
    conversions.push(eventData);
    localStorage.setItem('conversions', JSON.stringify(conversions));
  } catch (error) {
    console.error('Error storing conversion:', error);
  }
}

// Helper function to validate JSON strings
function isValidJSON(str: string): boolean {
  try {
    const parsed = JSON.parse(str);
    return parsed !== null && typeof parsed === 'object';
  } catch {
    return false;
  }
}

// Get current funnel stage
export function getFunnelStage(): number {
  try {
    const stored = localStorage.getItem('conversions');
    if (!stored) {
      return 0;
    }

    const conversions = JSON.parse(stored);
    if (!Array.isArray(conversions)) {
      console.error('Invalid conversions format');
      return 0;
    }

    const uniquePages = new Set(conversions.map((c: ConversionEvent) => c.page));
    return uniquePages.size;
  } catch (error) {
    console.error('Failed to get funnel stage:', error);
    return 0;
  }
}