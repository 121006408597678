/**
 * Type guard utilities for runtime type checking
 */

export function isNonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number' && !isNaN(value);
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

export function isObject(value: unknown): value is Record<string, unknown> {
  return Boolean(
    value &&
    typeof value === 'object' &&
    !Array.isArray(value) &&
    !(value instanceof Date) &&
    !(value instanceof RegExp)
  );
}

export function hasProperty<K extends string>(
  obj: unknown,
  prop: K
): obj is Record<K, unknown> {
  return isObject(obj) && prop in obj;
}

export function hasRequiredProperties<K extends string>(
  obj: unknown,
  props: K[]
): obj is Record<K, unknown> {
  return isObject(obj) && props.every(prop => prop in obj);
}

export function isValidType<T>(
  value: unknown,
  validator: (val: unknown) => val is T
): value is T {
  return validator(value);
}