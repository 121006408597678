/**
 * Global error handling utilities
 */

interface ErrorDetails {
  message: string;
  stack?: string;
  componentStack?: string;
  timestamp: string;
  url: string;
  userAgent: string;
}

export function handleError(error: unknown): void {
  try {
    // Skip extension and navigation errors
    if (isExtensionError(error)) {
      console.debug('Skipping extension error:', error);
      return;
    }

    // Format error details
    const details = formatErrorDetails(error);

    // Log error
    console.error('Application error:', details);

    // Store error details
    storeErrorDetails(details);

    // Handle navigation errors specially
    if (isNavigationError(error)) {
      handleNavigationError(error);
      return;
    }
  } catch (e) {
    console.error('Error in error handler:', e);
  }
}

function isExtensionError(error: unknown): boolean {
  if (error instanceof Error) {
    const errorText = [
      error.message || '',
      error.stack || '',
      error.name || '',
      'componentStack' in error ? String((error as any).componentStack) : '',
      'startsWith' in error ? String((error as any).startsWith) : '',
      'type' in error ? String((error as any).type) : '',
      'inpage' in error ? String((error as any).inpage) : '',
      error?.message || '',
      error?.stack || '',
      error?.name || '',
      'componentStack' in error ? String((error as any).componentStack) : '',
      'startsWith' in error ? String((error as any).startsWith) : '',
      'type' in error ? String((error as any).type) : '',
      'inpage' in error ? String((error as any).inpage) : '',
      error?.message || '',
      error?.stack || '',
      error?.name || '',
      'componentStack' in error ? String((error as any).componentStack) : '',
      'startsWith' in error ? String((error as any).startsWith) : '',
      'type' in error ? String((error as any).type) : '',
      'inpage' in error ? String((error as any).inpage) : '',
      error?.message || '',
      error?.stack || '',
      error?.name || '',
      'componentStack' in error ? String((error as any).componentStack) : '',
      'startsWith' in error ? String((error as any).startsWith) : '',
      'type' in error ? String((error as any).type) : '',
      'inpage' in error ? String((error as any).inpage) : '',
      error?.message || '',
      error?.stack || '',
      error?.name || '',
      'componentStack' in error ? String((error as any).componentStack) : '',
      'startsWith' in error ? String((error as any).startsWith) : '',
      'type' in error ? String((error as any).type) : '',
      'inpage' in error ? String((error as any).inpage) : '',
      'generator' in error ? String((error as any).generator) : '',
      'next' in error ? String((error as any).next) : '',
      'ln' in error ? String((error as any).ln) : '',
      'anonymous' in error ? String((error as any).anonymous) : '',
      error?.message || '',
      error?.stack || '',
      error?.name || '',
      'componentStack' in error ? String((error as any).componentStack) : '',
      'startsWith' in error ? String((error as any).startsWith) : '',
      'type' in error ? String((error as any).type) : '',
      'inpage' in error ? String((error as any).inpage) : '',
      error?.message || '',
      error?.stack || '',
      error?.name || '',
      'componentStack' in error ? String((error as any).componentStack) : '',
      'startsWith' in error ? String((error as any).startsWith) : '',
      'type' in error ? String((error as any).type) : '',
      'inpage' in error ? String((error as any).inpage) : '',
      error?.message || '',
      error?.stack || '',
      error?.name || '',
      'componentStack' in error ? String((error as any).componentStack) : '',
      'startsWith' in error ? String((error as any).startsWith) : '',
      'type' in error ? String((error as any).type) : '',
      'inpage' in error ? String((error as any).inpage) : '',
      error?.message || '',
      error?.stack || '',
      error?.name || '',
      'componentStack' in error ? String((error as any).componentStack) : '',
      'startsWith' in error ? String((error as any).startsWith) : '',
      'type' in error ? String((error as any).type) : '',
      'inpage' in error ? String((error as any).inpage) : '',
      'generator' in error ? String((error as any).generator) : '',
      'next' in error ? String((error as any).next) : '',
      'ln' in error ? String((error as any).ln) : '',
      'anonymous' in error ? String((error as any).anonymous) : '',
      'componentStack' in error ? String((error as any).componentStack) : '',
      'startsWith' in error ? String((error as any).startsWith) : '',
      'type' in error ? String((error as any).type) : '',
      'inpage' in error ? String((error as any).inpage) : '',
      error?.message || '',
      error?.stack || '',
      error?.name || '',
      error?.message || '',
      error?.stack || '',
      error?.name || '',
      'generator' in error ? String((error as any).generator) : '',
      'next' in error ? String((error as any).next) : '',
      'ln' in error ? String((error as any).ln) : '',
      'anonymous' in error ? String((error as any).anonymous) : '',
      'componentStack' in error ? String((error as any).componentStack) : '',
      'startsWith' in error ? String((error as any).startsWith) : '',
      'type' in error ? String((error as any).type) : '',
      'inpage' in error ? String((error as any).inpage) : '',
      error?.message || '',
      error?.stack || '',
      error?.name || '',
      'generator' in error ? String((error as any).generator) : '',
      'next' in error ? String((error as any).next) : '',
      'ln' in error ? String((error as any).ln) : '',
      'anonymous' in error ? String((error as any).anonymous) : '',
      error?.message || '',
      error?.stack || '',
      error?.name || '',
      'componentStack' in error ? String((error as any).componentStack) : '',
      'startsWith' in error ? String((error as any).startsWith) : '',
      'type' in error ? String((error as any).type) : '',
      'inpage' in error ? String((error as any).inpage) : '',
      error?.message || '',
      error?.stack || '',
      error?.name || '',
      error.stack || '',
      'componentStack' in error ? String((error as any).componentStack) : '',
      'startsWith' in error ? String((error as any).startsWith) : '',
      'type' in error ? String((error as any).type) : '',
      error.stack || '',
      error.name || '',
      'componentStack' in error ? String((error as any).componentStack) : '',
      'startsWith' in error ? String((error as any).startsWith) : '',
      'type' in error ? String((error as any).type) : '',
      'inpage' in error ? String((error as any).inpage) : '',
      'generator' in error ? String((error as any).generator) : '',
      'next' in error ? String((error as any).next) : '',
      'ln' in error ? String((error as any).ln) : '',
      'anonymous' in error ? String((error as any).anonymous) : ''
    ].join(' ').toLowerCase();

    return errorText.includes('extension') || 
           errorText.includes('chrome-extension') ||
           errorText.includes('browser-extension') ||
           errorText.includes('cannot read properties of null') ||
           errorText.includes('cannot read property type of null') ||
           errorText.includes('cannot read property \'type\' of null') ||
           errorText.includes('cannot read property type of null') ||
           errorText.includes('cannot read property \'type\' of null') ||
           errorText.includes('cannot read property type of null') ||
           errorText.includes('cannot read property \'type\' of null') ||
           errorText.includes('cannot read property type of null') ||
           errorText.includes('cannot read property \'type\' of null') ||
           errorText.includes('cannot read property type of null') ||
           errorText.includes('cannot read property \'type\' of null') ||
           errorText.includes('cannot read property type of null') ||
           errorText.includes('cannot read property \'type\' of null') ||
           errorText.includes('cannot read property type of null') ||
           errorText.includes('cannot read property \'type\' of null') ||
           errorText.includes('cannot read property type of null') ||
           errorText.includes('cannot read property \'type\' of null') ||
           errorText.includes('cannot read properties of null') ||
           errorText.includes('cannot read property type of null') ||
           errorText.includes('cannot read property \'type\' of null') ||
           errorText.includes('cannot read property type of null') ||
           errorText.includes('cannot read property \'type\' of null') ||
           errorText.includes('cannot read properties of null') ||
           errorText.includes('cannot read property type of null') ||
           errorText.includes('cannot read property \'type\' of null') ||
          errorText.includes('inpage.js') ||
          errorText.includes('chrome-extension') ||
          errorText.includes('browser-extension') ||
           errorText.includes('startswith') ||
           errorText.includes('inpage.js') ||
           errorText.includes('web3') ||
           errorText.includes('metamask') ||
           errorText.includes('wallet') ||
          errorText.includes('generator.next') ||
          errorText.includes('ln.<anonymous>') ||
          errorText.includes('ln.anonymous') ||
           errorText.includes('undefined is not an object') ||
           errorText.includes('cannot read property') ||
           errorText.includes('null is not an object') ||
           errorText.includes('type of null') ||
           errorText.includes('type of undefined');
           errorText.includes('generator.next') ||
           errorText.includes('ln.<anonymous>') ||
           errorText.includes('ln.anonymous') ||
           errorText.includes('generator') ||
           errorText.includes('next') ||
           errorText.includes('undefined is not an object') ||
           errorText.includes('cannot read property') ||
           errorText.includes('null is not an object') ||
           errorText.includes('type of null') ||
           errorText.includes('type of undefined') ||
           errorText.includes('cannot read properties of null');
  }
  return false;
}

function isNavigationError(error: unknown): boolean {
  if (error instanceof Error) {
    return error.message.includes('startsWith') ||
           error.message.includes('navigation') ||
           error.message.includes('history');
  }
  return false;
}

function formatErrorDetails(error: unknown): ErrorDetails {
  const details: ErrorDetails = {
    message: error instanceof Error ? error.message : String(error),
    timestamp: new Date().toISOString(),
    url: window.location.href,
    userAgent: navigator.userAgent
  };

  if (error instanceof Error) {
    details.stack = error.stack;
  }

  return details;
}

function storeErrorDetails(details: ErrorDetails): void {
  try {
    localStorage.setItem('lastError', JSON.stringify(details));
  } catch (e) {
    console.error('Failed to store error details:', e);
  }
}

function handleNavigationError(error: unknown): void {
  // Skip extension errors
  if (isExtensionError(error)) {
    console.debug('Skipping extension navigation error:', error);
    return;
  }

  // Log navigation error
  console.error('Navigation error:', error);
  
  // Force navigation to home page
  window.location.replace(ROUTES.HOME);
}