import { trackConversion } from './analytics';
import { ROUTES } from './routes';

const SESSION_DURATION = 24 * 60; // 24 hours

interface Session {
  token: string;
  expires: number;
  trialEnds?: number;
  subscriptionActive: boolean;
  selectedPlan?: string;
  trialStarted: boolean;
}

interface AuthSession {
  token: string;
  email: string;
  expires: number;
  isLoggedIn: boolean;
}

function createSession(): Session {
  const now = Date.now();
  return {
    token: generateToken(),
    expires: now + SESSION_DURATION * 60 * 1000,
    trialEnds: now + 7 * 24 * 60 * 60 * 1000, // 7-day trial
    subscriptionActive: false,
    trialStarted: false
  };
}

function generateToken(): string {
  try {
    return Math.random().toString(36).substring(2);
  } catch (error) {
    console.error('Error generating token:', error);
    return Date.now().toString(36);
  }
}

function isValidJSON(str: string): boolean {
  try {
    const parsed = JSON.parse(str);
    return Boolean(parsed && typeof parsed === 'object');
  } catch {
    return false;
  }
}

function isValidSession(session: unknown): session is Session {
  return Boolean(
    session &&
    typeof session === 'object' &&
    'token' in session &&
    'expires' in session
  );
}
export function startTrial(plan: string): void {
  const session = createSession();
  const now = Date.now();
  const trialDuration = 7 * 24 * 60 * 60 * 1000; // 7 days

  session.selectedPlan = plan;
  session.trialStarted = true;
  session.trialEnds = now + trialDuration;

  localStorage.setItem('session', JSON.stringify(session));
  localStorage.setItem('accessToken', session.token);
  localStorage.setItem('trialStartDate', now.toString());
  localStorage.setItem('trialStarted', 'true');
  localStorage.setItem('trialEndDate', (now + trialDuration).toString());

  // Track trial start
  trackConversion({
    type: 'trial_start',
    page: window.location.pathname as any,
    data: { plan }
  });
}

export function handleGetStarted(source: string): void {
  try {
    const safeSource = source || 'direct';
    
    trackConversion({
      type: 'cta_click',
      page: window.location.pathname as any,
      data: { source: safeSource.trim(), button: 'get_started' }
    });

    // Force navigation using replace to avoid history issues
    window.location.replace(ROUTES.PRICING);
  } catch (error) {
    console.error('Error handling get started:', error);
    window.location.replace(ROUTES.PRICING);
  }
}

export function startFreeTrial(plan: string, source?: string): void {
  try {
    if (!plan) {
      // Redirect to pricing if no plan selected
      window.location.href = ROUTES.PRICING;
      return;
    }
    // Track conversion
    trackConversion({
      type: 'cta_click',
      page: window.location.pathname as any,
      data: { source, button: 'free_trial' }
    });

    // Redirect to pricing page
    window.location.href = ROUTES.PRICING;
  } catch (error) {
    console.error('Error starting trial:', error);
    // Fallback using replace
    window.location.replace(ROUTES.PRICING);
  }
}

export function activateSubscription(): void {
  const sessionData = localStorage.getItem('session');
  if (!sessionData) return;

  const session: Session = JSON.parse(sessionData);
  session.subscriptionActive = true;
  localStorage.setItem('session', JSON.stringify(session));
  localStorage.setItem('subscriptionActive', 'true');
}

export function checkToolAccess(): boolean {
  const sessionData = localStorage.getItem('session');
  const subscriptionActive = localStorage.getItem('subscriptionActive') === 'true';
  
  try {
    if (!sessionData) return false;

    const session: Session = JSON.parse(sessionData);
    const now = Date.now();

    // Check if session is expired
    if (now > session.expires) {
      localStorage.removeItem('session');
      localStorage.removeItem('accessToken');
      return false;
    }

    // Allow access during trial period or with active subscription
    return now < (session.trialEnds || 0) || subscriptionActive;
  } catch (error) {
    console.error('Error checking tool access:', error);
    return false;
  }
}

const REMEMBER_ME_KEY = 'rememberMe';
const LAST_EMAIL_KEY = 'lastEmail';

export function rememberEmail(email: string): void {
  localStorage.setItem(REMEMBER_ME_KEY, 'true');
  localStorage.setItem(LAST_EMAIL_KEY, email);
}

export function getRememberedEmail(): string | null {
  return localStorage.getItem(REMEMBER_ME_KEY) === 'true' 
    ? localStorage.getItem(LAST_EMAIL_KEY)
    : null;
}

export function forgetEmail(): void {
  localStorage.removeItem(REMEMBER_ME_KEY);
  localStorage.removeItem(LAST_EMAIL_KEY);
}

export function login(email: string): void {
  const session: AuthSession = {
    token: Math.random().toString(36).substring(2),
    email,
    expires: Date.now() + SESSION_DURATION * 60 * 1000,
    isLoggedIn: true
  };
  
  // Set trial status if not already set
  if (!localStorage.getItem('trialStarted')) {
    localStorage.setItem('trialStarted', 'true');
    localStorage.setItem('trialStartDate', Date.now().toString());
    localStorage.setItem('trialEndDate', (Date.now() + 7 * 24 * 60 * 60 * 1000).toString());
  }

  localStorage.setItem('session', JSON.stringify(session));
  localStorage.setItem('accessToken', session.token);
  localStorage.setItem('userEmail', email);
  localStorage.setItem('lastLoginTime', Date.now().toString());
  
  // Track successful login
  trackConversion({ type: 'login_success', page: window.location.pathname as any, data: { email } });
}

export function logout(): void {
  // Track last session duration
  const lastLogin = localStorage.getItem('lastLoginTime');
  if (lastLogin) {
    const duration = Date.now() - parseInt(lastLogin);
    trackConversion({ 
      type: 'logout',
      page: window.location.pathname as any,
      data: { sessionDuration: duration }
    });
  }

  localStorage.removeItem('session');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('userEmail');
  localStorage.removeItem('trialStartDate');
  localStorage.removeItem('trialStarted');
  localStorage.removeItem('subscriptionActive');
  localStorage.removeItem('lastLoginTime');
  
  // Redirect to home page
  window.location.href = ROUTES.HOME;
}

export function checkAuth(): boolean {
  try {
    const sessionData = localStorage.getItem('session');
    if (!sessionData) {
      return false;
    }
    
    let session: AuthSession;
    try {
      session = JSON.parse(sessionData);
      if (!session?.token || !session?.expires) return false;
    } catch {
      localStorage.removeItem('session');
      localStorage.removeItem('accessToken');
      return false;
    }

    const now = Date.now();

    // Check if session is expired
    if (now > session.expires) {
      logout();
      return false;
    }

    return Boolean(session.isLoggedIn);
  } catch (error) {
    console.error('Error checking auth:', error);
    // Clean up invalid session data
    localStorage.removeItem('session');
    localStorage.removeItem('accessToken');
    return false;
  }
}

export function redirectToSignup() {
  // Clear any existing signup progress
  localStorage.removeItem('signupStep');
  localStorage.removeItem('accessToken');
  
  // Force navigation using replace to avoid history issues
  window.location.replace(ROUTES.SIGNUP);
}

export function redirectToPricing() {
  window.location.replace(ROUTES.PRICING);
}

export function checkWorkspaceAccess(): boolean {
  try {
    // Check Stripe payment status first
    const stripeSession = localStorage.getItem('stripe_session');
    if (stripeSession) {
      try {
        const { status } = JSON.parse(stripeSession);
        if (status === 'complete') {
          return true;
        }
      } catch {
        localStorage.removeItem('stripe_session');
      }
    }

    // Then check subscription/trial status
    const hasSubscription = localStorage.getItem('subscriptionActive') === 'true';
    const hasTrial = localStorage.getItem('trialStarted') === 'true';
    
    // Check trial expiry if on trial
    if (hasTrial && !hasSubscription) {
      const trialStartDate = localStorage.getItem('trialStartDate');
      if (!trialStartDate) {
        localStorage.removeItem('trialStarted');
        return false;
      }
      const trialStart = parseInt(trialStartDate);
      const trialDuration = 7 * 24 * 60 * 60 * 1000; // 7 days
      if (Date.now() > trialStart + trialDuration) {
        localStorage.removeItem('trialStarted');
        return false;
      }
    }

    // Return true if user has subscription or valid trial
    return hasSubscription || hasTrial;
  } catch (error) {
    console.error('Error checking workspace access:', error);
    return false;
  }
}

export function redirectToWorkspace(): void {
  window.location.href = ROUTES.WORKSPACE.ROOT;
}

export function isAuthenticated(): boolean {
  return localStorage.getItem('accessToken') === 'true';
}

export function handleGoogleAuth(code: string): Promise<void> {
  return new Promise((resolve, reject) => {
    try {
      if (!code) {
        throw new Error('Authorization code is required');
      }

      // Track signup
      trackConversion({
        type: 'signup_start',
        page: window.location.pathname as any,
        data: { source: 'google' }
      });

      // Get redirect URL
      const redirectTo = localStorage.getItem('redirectAfterAuth') || ROUTES.WORKSPACE.ROOT;
      localStorage.removeItem('redirectAfterAuth');
      
      // Create session
      const session = createSession();
      localStorage.setItem('session', JSON.stringify(session));
      localStorage.setItem('accessToken', session.token);
      
      // Redirect
      window.location.href = redirectTo;
      resolve(); 
    } catch (error) {
      console.error('Google auth error:', error);
      reject(error);
    }
  });
}

/**
 * Checks if the user's trial period has expired
 */
export function checkTrialExpired(): boolean {
  try {
    const trialStartDate = localStorage.getItem('trialStartDate');
    if (!trialStartDate) return true;

    const startDate = parseInt(trialStartDate);
    const trialDuration = 7 * 24 * 60 * 60 * 1000; // 7 days
    
    return Date.now() > startDate + trialDuration;
  } catch (error) {
    console.error('Error checking trial expiry:', error);
    return true; // Fail safe - assume trial expired if error
  }
}

export function handlePostCheckout() {
  const stripeSession = localStorage.getItem('stripe_session');
  const returnUrl = sessionStorage.getItem('returnTo');
  
  if (stripeSession) {
    try {
      const { status } = JSON.parse(stripeSession);
      if (status === 'complete') {
        localStorage.setItem('subscriptionActive', 'true');
        localStorage.removeItem('stripe_session');
        
        trackConversion({
          type: 'purchase_complete',
          page: window.location.pathname as any,
          data: { source: 'stripe' }
        });
        
        const returnUrl = sessionStorage.getItem('returnTo') || ROUTES.WORKSPACE;
        sessionStorage.removeItem('returnTo');
        
        // Redirect to workspace dashboard
        window.location.href = returnUrl || ROUTES.WORKSPACE.DASHBOARD;
      }
    } catch (error) {
      console.error('Error handling post-checkout:', error);
      window.location.href = ROUTES.PRICING;
    }
  }
}

export function handleCheckoutSuccess(sessionId: string): void {
  try {
    // Store Stripe session
    localStorage.setItem('stripe_session', JSON.stringify({
      id: sessionId,
      status: 'complete'
    }));

    // Activate subscription
    localStorage.setItem('subscriptionActive', 'true');
    localStorage.setItem('trialStartDate', Date.now().toString());
    localStorage.setItem('trialEndDate', (Date.now() + 7 * 24 * 60 * 60 * 1000).toString());
    localStorage.setItem('trialStarted', 'true');
    
    // Track conversion
    trackConversion({
      type: 'purchase_complete',
      page: window.location.pathname as any,
      data: { sessionId }
    });

    // Get return URL with fallback
    const returnUrl = sessionStorage.getItem('returnTo') || ROUTES.WORKSPACE.DASHBOARD;
    sessionStorage.removeItem('returnTo');

    // Redirect to workspace
    window.location.href = ROUTES.WORKSPACE;
  } catch (error) {
    console.error('Error handling checkout success:', error);
    window.location.replace(ROUTES.WORKSPACE);
  }
}