export const ROUTES = {
  HOME: '/',
  VENTURE: '/venture',
  VENTURE_BUILDER: '/venture-builder',
  START_AI_VENTURE: '/start-ai-venture',
  VENTURE_ASSESSMENT: '/venture/assessment',
  CAREER_CALCULATOR: '/career-calculator',
  VENTURE_CONSULTATION: '/venture/consultation',
  ASSESSMENT: '/assessment',
  GOVERNMENT: '/government',
  GOVERNMENT_SOLUTIONS: '/government/solutions',
  GOVERNMENT_ASSESSMENT: '/government/assessment', 
  GOVERNMENT_CONSULTATION: '/government/consultation', 
  GOVERNMENT_GRANTS: '/government/grants',
  GOVERNMENT_CAPABILITY: '/government/capability-statement',
  ENTERPRISE: '/enterprise',
  ENTERPRISE_ASSESSMENT: '/enterprise/assessment',
  AUTOMATION: '/automation',
  AUTOMATION_ONBOARDING: '/automation/onboarding',
  AUTOMATION_DEMO: '/automation/demo',
  AUTOMATION_CHECKOUT: '/automation/checkout',
  CHOOSE_PLAN: '/choose-plan', 
  AI_SOFTWARE_DEV: '/ai-software-development',
  LOGIN: '/login',
  AI_TOOLS: {
    ROOT: '/ai-tools',
    DIRECTORY: '/ai-tools/directory', 
    CATEGORIES: '/ai-tools/categories',
    EXPLORE: '/ai-tools/explore',
    COMPARE: '/ai-tools/compare'
  },
  CHECKOUT_SUCCESS: '/checkout/success',
  WORKSPACE: {
    ROOT: '/workspace',
    DASHBOARD: '/workspace/dashboard',
    TOOLS: '/workspace/tools',
    SETTINGS: '/workspace/settings',
    BILLING: '/workspace/billing',
    PROFILE: '/workspace/profile'
  },
  ADMIN: {
    ROOT: '/admin',
    LOGIN: '/admin/login',
    DASHBOARD: '/admin/dashboard',
    USERS: '/admin/users',
    SETTINGS: '/admin/settings',
    ANALYTICS: '/admin/analytics',
    SECURITY: '/admin/security'
  },
  PRICING: '/pricing',
  ABOUT: '/about',
  PRIVACY: '/privacy',
  TERMS: '/terms',
  SECURITY: '/security',
  STATUS: '/status',
  IMPACT: '/impact',
  CASE_STUDIES: '/case-studies',
  INVESTOR_NETWORK: '/investor-network',
 DEAL_FLOW: '/deal-flow',
  VENTURE_MARKETPLACE: '/venture-marketplace',
  API: '/api-reference',
  CONTACT: '/contact',
  CAREERS: '/careers',
  PRESS_KIT: '/press-kit',
  COMMUNITY: '/community',
  DOCUMENTATION: '/docs',
  SUPPORT: '/support',
  TUTORIALS: '/tutorials',
  COMPLIANCE: '/compliance',
  TOOL: '/tools/'
} as const;

export type Route = typeof ROUTES[keyof typeof ROUTES] | `${typeof ROUTES['TOOL']}${string}`;

export function isValidRoute(path: string): path is Route {
  try {
    // Handle root path
    if (path === '/') return true;

    // Handle tool routes
    if (path.startsWith(ROUTES.TOOL)) return true;

    // Handle automation sub-routes
    if (path === ROUTES.AUTOMATION_ONBOARDING || path === ROUTES.AUTOMATION_DEMO) return true;

    // Handle other routes
    const normalizedPath = path.endsWith('/') ? path.slice(0, -1) : path;
    return Object.values(ROUTES).includes(normalizedPath as Route);
  } catch (error) {
    console.error('Route validation error:', error);
    return false;
  }
}

// Add 404.html for SPA fallback
export function generateSPAFallback() {
  return `<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8">
    <title>GALAX AI</title>
    <script>
      sessionStorage.redirect = location.href;
    </script>
    <meta http-equiv="refresh" content="0;URL='/'">
  </head>
  <body>
    Redirecting...
  </body>
</html>`;
}

// Handle browser back/forward navigation
if (typeof window !== 'undefined') {
  window.addEventListener('popstate', () => {
    try {
      // Handle 404 redirects
      const path = window.location.pathname;
      const search = window.location.search;
      
      // Check if we're on a 404 page with redirect info
      if (search.includes('?/')) {
        const redirectPath = search.substring(2);
        history.replaceState(null, '', redirectPath);
        window.dispatchEvent(new Event('routechange'));
        return;
      }

      // Handle assessment completion
      if (window.location.pathname.includes('assessment') && localStorage.getItem('assessmentAnswers')) {
        window.location.href = ROUTES.CHOOSE_PLAN;
        return;
      }

      // Check for redirect from 404.html
      const redirect = sessionStorage.redirect;
      delete sessionStorage.redirect;
      
      if (redirect && redirect !== location.href) {
        history.replaceState(null, '', redirect);
        window.dispatchEvent(new Event('routechange'));
        return;
        window.dispatchEvent(new Event('routechange'));
        return;
      }

      // Get current path
      const isStripeReturn = document.referrer.includes('stripe.com');
    
      // Handle special cases
      if (path === ROUTES.TOOLS || path.startsWith(`${ROUTES.TOOLS}#`)) {
        // Redirect tools routes to signup
        window.location.href = ROUTES.SIGNUP;
        return;
      }

      // Handle Stripe return
      if (isStripeReturn && path === ROUTES.PRICING) {
        window.location.href = ROUTES.WORKSPACE;
        return;
      }
    
      // Force re-render of the app
      if (!path.includes('404.html')) {
        window.dispatchEvent(new Event('routechange'));
      }
    } catch (error) {
      console.error('Navigation error:', error);
      // Fallback to home page
      window.location.href = ROUTES.HOME;
    }
  });
}